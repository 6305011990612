import axios from 'axios';
import { useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';

// import { setCookie } from 'src/utils/cookie';
import { refreshTokenAPI } from 'app/services/api/auth';
import moment from 'moment';

export const useAutoRefreshToken = () => {
  const refresh_token = localStorage.getItem('accessToken');
  const refreshToken = useCallback(async () => {
    if (refresh_token) {
      const params = {
        token: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
      };
      try {
        const resp = await refreshTokenAPI(params);
        const validToken = resp?.token;

        localStorage.setItem('accessToken', validToken);
        localStorage.setItem('refreshToken', resp.refreshToken);
      } catch (err) {
        console.log({ err });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scheduleTokenRefresh = useCallback(
    currentToken => {
      let refreshTimer;
      try {
        if (currentToken) {
          // const expiryTime = (jwtDecode('currentToken')?.exp as any) * 1000;
          const timeRefreshToken = moment((jwtDecode(currentToken)?.exp as any) * 1000).diff(moment(), 'minutes');
          // const timeRefreshToken = moment(currentToken * 1000).diff(moment(), 'minutes');
          // console.log('timeRefreshToken :>> ', timeRefreshToken);
          localStorage.setItem('timeRefreshToken', timeRefreshToken.toString());
          // console.log('timeRefreshToken :>> ', timeRefreshToken);
          //3 > x > 1 thì là reset token
          // >1 thì là okkk
          // <1 thì là login
          // if (timeRefreshToken < 1) {
          //   console.log('login ............... :>> ');
          // } else if (1 < timeRefreshToken && timeRefreshToken < 3) {
          //   console.log('refreshToken ............... :>> ');
          // } else {
          //   console.log('ok ............... :>> ');
          // }
        }
      } catch (error) {
        console.log({ error });
      }
    },
    [refreshToken],
  );

  return { refreshToken, scheduleTokenRefresh };
};
