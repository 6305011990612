import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { refreshTokenAPI } from './auth';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';

interface RefreshTokenResponse {
  token: string;
  refreshToken: string;
}

const client = axios.create({
  baseURL: process.env.REACT_APP_HOST_ENV,
  timeout: 300000,
});

let isRefreshing = false;
let isCallRefeshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

function onRrefreshed(token: string) {
  refreshSubscribers.forEach(callback => callback(token));
  refreshSubscribers = [];
}

function addSubscriber(callback: (token: string) => void) {
  refreshSubscribers.push(callback);
}

client.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (config.method === 'get') {
      const currentTime = new Date().getTime();
      const oldUrl = config.url || '';
      let newUrl = config.url;
      if (oldUrl.includes('?')) {
        newUrl = `${oldUrl}&time=${currentTime}`;
      } else {
        newUrl = `${oldUrl}?time=${currentTime}`;
      }
      config.url = newUrl;
    }

    const cloneConfig: any = { ...config };
    const accessToken = localStorage.getItem('accessToken');
    const timeRefreshToken = localStorage.getItem('timeRefreshToken') as string;
    const params = {
      token: localStorage.getItem('accessToken')?.trim() || '',
      refreshToken: localStorage.getItem('refreshToken')?.trim() || '',
    };

    if (1 < Number(timeRefreshToken) && Number(timeRefreshToken) < 4) {
      isCallRefeshing = true;
    }
    if (accessToken && !config.url?.includes('refresh-token')) {
      cloneConfig.headers = {
        ...cloneConfig.headers,
        Auth: `bearer ${accessToken}`,
      };
    }
    return cloneConfig;
  },
  error => Promise.reject(error),
);

client.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: any) => {
    const originalRequest: any = error.config;
    console.log('error :>> ', error);
    if (error.response.status === 401 && !isCallRefeshing) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/login';
    }
    if (error.response.status === 401 && !originalRequest._retry && isCallRefeshing) {
      if (isRefreshing) {
        return new Promise(resolve => {
          addSubscriber((token: string) => {
            originalRequest.headers['Auth'] = `bearer ${token}`;
            resolve(client(originalRequest));
          });
        });
      }
      const accessToken = localStorage.getItem('accessToken');
      originalRequest._retry = true;
      isRefreshing = true;

      const params = {
        token: localStorage.getItem('accessToken')?.trim() || '',
        refreshToken: localStorage.getItem('refreshToken')?.trim() || '',
      };

      try {
        const resp: RefreshTokenResponse = await refreshTokenAPI(params);
        localStorage.setItem('accessToken', resp.token);
        localStorage.setItem('refreshToken', resp.refreshToken);
        isCallRefeshing = false;
        isRefreshing = false;
        onRrefreshed(resp.token);
        originalRequest.headers['Auth'] = `bearer ${resp.token}`;
        return client(originalRequest);
      } catch (refreshError) {
        isRefreshing = false;
        return Promise.reject(refreshError);
      }
    } else {
      // localStorage.clear();
      // sessionStorage.clear();
      // window.location.href = '/login';
    }

    return Promise.reject(error.response.data);
  },
);

export default client;
