export enum ActionType {
  VIEW = 'View',
  ADD = 'Add',
  EDIT = 'Edit',
  EDIT_APPLICATION = 'Edit Application',
  DELETE = 'Delete',
  APPROVE = 'Approve',
  REASSIGN = 'Reassign',
  ACTIVITY = 'Activity',
}

export enum ModuleName {
  Acceptance = 'Acceptance',
  AcceptanceCriteria = 'AcceptanceCriteria',
  AdminFunction = 'AdminFunction',
  LicenceManagement = 'Licence Management',
  AIML = 'AI&ML',
  BatchScheduling = 'BatchScheduling',
  BatchAcceptanceScheduling = 'BatchAcceptanceScheduling',
  Dashboard = 'Dashboard',
  DecisionTable = 'DecisionTable',
  Defect = 'Defect',
  Document = 'Document',
  EnginuityWorkbench = 'EnginuityWorkbench',
  Execute = 'Execute',
  Information = 'Information',
  Release = 'Release',
  Report = 'Report',
  ServiceCatalogue = 'ServiceCatalogue',
  TestDesign = 'TestDesign',
  TestLab = 'TestLab',
  UserStories = 'UserStories',
  VirtualTesterMonitor = 'VirtualTesterMonitor',
  Workbench = 'Workbench',
  Worklist = 'Worklist',
  UserManagement = 'Admin User Management',
  LicenceProduct = 'Licence Product',
  LicenceType = 'Licence Type',
  Company = 'Company',
  Subscription = 'Subscription',
  LicenceApprovalRequest = 'Licence Approval Request',
}
